.training-stage li::marker {
  content: none;
}

.stage-header h2 {
  text-align: center;
}

.training-stage > ul {
  padding-left: 0;
  margin-top: 0;
}

.training-stage > h2 {
  text-align: center;
  margin-bottom: 0.2em;
}

.training-stage--restart {
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

.training-stage--restart-link {
  background-color: #2e6dff;
  color: white;

  margin: 0 auto;
  padding: 0.5em;
}

.training-stage--restart-link:visited {
  color: white;
}
