.trainings > h2 {
  text-align: center;
}

.trainings--list {
  list-style-type: none;
  padding-left: 0;
}

.trainings--training {
  margin: 0.2em 0;
}

.trainings--training-enabled {
  background-color: #2e6dff;
}

.trainings--training-disabled {
  background-color: #c0c0c0;
}

.trainings--training a {
  text-decoration: none;
}

.trainings--training-link {
  padding: 0.2em 1em;

  text-transform: uppercase;
  color: white;
}
