.home > h1 {
  text-align: center;
}

.home-intro > h2 {
  margin-bottom: 0.1em;
}

.home-intro > p {
  margin-top: 0;
}

.home--start {
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

.home--start-link {
  background-color: #2e6dff;
  color: white;

  margin: 0 auto;
  padding: 0.5em;
}

.home--start-link:visited {
  color: white;
}
