/*
.training-task--incomplete::before {
  content: "☐ ";
}

.training-task--complete::before {
  content: "☒ ";
}
*/

.training-task {
  padding: 0.2em 0.8em;
  margin: 0.2em 0;
}

.training-task--incomplete {
  background-color: #c0c0c0;
}

.training-task--complete {
  background-color: #1ed21e;
}
