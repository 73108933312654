.app {
  background-color: #f0f0f0;
  color: #303030;
  font-size: 2.5em;

  min-height: 100vh;
}

.app-main {
  padding: 0 0.35em;
}

.app-header {
  display: flex;
  background-color: #2e6dff;

  justify-content: center;
  align-content: center;

  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

.agema-logo {
  height: 10vmin;
  pointer-events: none;
}
